// Copyright © 2024 CATTLEytics Inc.

import { differenceInDays } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import AnimalModal from '../../../animals/components/AnimalModal';
import AlertErrorForModal from '../../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import DatePicker from '../../../common/components/DatePicker';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import Pen from '../../../common/entities/pen';
import { AnimalEventType as AnimalEventTypeEnum } from '../../../common/enums';
import AuthContext from '../../../common/store/auth-context';
import { useSettingsContext } from '../../../common/store/useSettingsContext';
import { api, IconSave, IconWarning } from '../../../common/utilities';
import { Animal } from '../../../shared';
import { User } from '../../../shared';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../../shared/enums';
import UserAutocomplete from '../../../users/components/UserAutocomplete';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully..
   */
  onSubmitSuccess?: (animalEvent: AnimalEvent) => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

export interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  eventDateTime: string;
  notes: string;
  userId: number;
}

export const PregnantForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const settings = useSettingsContext();

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showAddAnimalModal, setShowAddAnimalModal] = useState<boolean>(false);

  const [animals, setAnimals] = useState<Animal[]>([]);
  const [eventDateTime, setEventDateTime] = useState<Date | undefined>(
    zonedTimeToUtc(new Date(), settings.timeZone),
  );
  const [bredDate, setBredDate] = useState<string>();
  const [notes, setNotes] = useState<string>('');
  const [user, setUser] = useState<User>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  const { data: bredEventData } = useQuery(
    [QueryKey.AnimalEvents, 'BredEvents', `animal-${animals.join(',')}`],
    () =>
      api<AnimalEvent[]>(HttpMethod.Get, ApiResourceV1.AnimalEvents, {
        params: {
          animalId: String(animals.length > 0 ? animals[0].id : -1),
          animalEventTypeIds: String(AnimalEventTypeEnum.Bred),
          sortField: 'eventDateTime',
          sortDirection: 'DESC',
          limit: String(5),
        },
      }),
  );

  const { isLoading: isSaving, mutateAsync: saveAnimalEventAsync } = useMutation<
    AnimalEvent[] | undefined,
    unknown,
    Payload
  >(
    (payload) => {
      return api(HttpMethod.Post, ApiResourceV1.AnimalEvents, { body: payload });
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
        await queryClient.invalidateQueries(QueryKey.Animals);
      },
    },
  );

  const auth = useContext(AuthContext);

  useQuery<User | undefined>(
    [QueryKey.Users, auth.userId],
    () => api(HttpMethod.Get, `${ApiResourceV1.Users}/${auth.userId}`),
    {
      onSuccess: (user) => {
        if (user) {
          setUser(user);
        }
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const updateNotes = (date: string): void => {
    // take the difference from eventDateTime to the selected date
    // date is formatted like: formatInTimeZone(d.eventDateTime, settings.timeZone, settings.dateFormat)
    if (eventDateTime === undefined) {
      return;
    }
    // eventDateTime is in the localTz

    const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const verifiedDate = formatInTimeZone(eventDateTime, userTz, "yyyy-MM-dd'T'HH:mm");

    const days = differenceInDays(
      zonedTimeToUtc(verifiedDate, settings.timeZone),
      zonedTimeToUtc(date, settings.timeZone),
    );
    setNotes(`${days} DAYS`);
    setBredDate(date);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid || !user || !eventDateTime) {
      return;
    }

    const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const verifiedDate = formatInTimeZone(eventDateTime, userTz, "yyyy-MM-dd'T'HH:mm");

    const payload: Payload = {
      animalIds: animals.map((animal) => animal.id),
      animalEventTypeId: AnimalEventTypeEnum.Pregnant,
      eventDateTime: zonedTimeToUtc(verifiedDate, settings.timeZone).toISOString(),
      notes: notes,
      userId: user.id,
    };

    setErrorMessage('');
    try {
      const result = await saveAnimalEventAsync(payload);
      if (result?.length && props.onSubmitSuccess) {
        props.onSubmitSuccess(result[0]);
      }
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="formEventDate">
        <Form.Label>
          {t('Event Date')} <Required />
        </Form.Label>
        <DatePicker
          aria-label={t('Event Date')}
          dateFormat="yyyy-MM-dd, h:mm aa"
          onChange={(event): void => {
            // event is in the localTz.
            if (event) {
              setEventDateTime(event);
            } else {
              setEventDateTime(undefined);
            }
          }}
          required={true}
          selected={eventDateTime}
          selectsRange={false}
          showTimeSelect
          showTwoColumnMonthYearPicker
          wrapperClassName={'w-100'}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formAnimals">
        <Form.Label>
          {t('Animal')} <Required />
        </Form.Label>
        <AnimalAutocomplete
          genderId={2}
          id={'animals'}
          multiple={false}
          name={'animalIds'}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setAnimals([]);
            }

            setAnimals([selected]);
          }}
          required={true}
          selected={animals}
          validated={validated}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose an {{value}} from the dropdown or', { value: 'Animal' })} </span>
          <Button onClick={(): void => setShowAddAnimalModal(true)} size={'sm'} variant={'link'}>
            {t('add animal')}
          </Button>
        </Form.Text>
        {!!(animals.length && animals[0].isCalf) && (
          <Alert variant={'warning'}>
            <IconWarning />
            {` ${t('pregnantForm|calfPregWarning')}`}
          </Alert>
        )}
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="formAnimals">
        <Form.Label>{t('pregnantForm|linkedBreedEventLabel')}</Form.Label>
        <Form.Select onChange={(ev): void => updateNotes(ev.target.value)} value={bredDate}>
          <option>{t('pregnantForm|defaultBredDate')}</option>
          {(bredEventData ?? []).map((d) => {
            return (
              <option value={utcToZonedTime(d.eventDateTime, settings.timeZone).toISOString()}>
                {formatInTimeZone(d.eventDateTime, settings.timeZone, settings.dateFormat)}
              </option>
            );
          })}
        </Form.Select>

        <Form.Text className="text-muted">
          <span>{t('pregnantForm|linkedBreedEventInstructions')} </span>
        </Form.Text>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="UserControlInput">
        <Form.Label>{t('Technician')}</Form.Label>
        <UserAutocomplete
          id={'user'}
          multiple={false}
          name={'userId'}
          onCloseAddModal={(): void => setShowAddUserModal(false)}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setUser(undefined);
            }
            setUser(selected);
          }}
          required={false}
          selected={user}
          showAddModal={showAddUserModal}
          validated={validated}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a {{value}} from the dropdown or', { value: 'user' })} </span>
          <Button onClick={(): void => setShowAddUserModal(true)} size={'sm'} variant={'link'}>
            {t('add user')}
          </Button>
        </Form.Text>
      </Form.Group>

      <FormGroup className="mb-3" controlId="formNotes">
        <Form.Label>{t('Notes')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(event): void => setNotes(event.target.value)}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>{t('common|eventDescriptionInstructions')}</Form.Text>
      </FormGroup>
    </>
  );
  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModal
          disabled={isSaving}
          label={t('Cancel')}
          onClick={props.onCancel}
          type={'button'}
          variant={ButtonVariant.Secondary}
        />
        <ButtonModal
          busy={isSaving}
          disabled={isSaving}
          icon={IconSave}
          label={t('Save')}
          type="submit"
        />
      </div>
      {showAddAnimalModal && <AnimalModal onClose={(): void => setShowAddAnimalModal(false)} />}
    </Form>
  );
};
